import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import { Card, Row, Col, Container } from 'react-bootstrap'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

const PdpMemberTemplate = ({ data }) => {
  if (!data) return null

  const pdpMemberContent = data.prismicPdpMember
  const pdpMember = pdpMemberContent.data || {}

  const { lang, type, url } = pdpMemberContent
  const alternateLanguages = pdpMemberContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicMenuLink || {}
  return (
    <Layout
      topMenu={topMenu.data}
      activeDocMeta={activeDoc}
      isHomerepage={false}
    >
      <Seo title={pdpMember.program_name} lang={lang} />
      <Container className="pdpMember_wrapper">
        <div className="collection-page-title">
          <h1>ENTER Registered Professional Development Program</h1>
        </div>
        <hr />
        <Row className="mt-2 mb-5">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="d-flex justify-content-between bg-secondary bg-gradient text-white p-2 h6 shadow">
                  {pdpMember.program_name_label}
                  <span>{pdpMember.program_name}</span>
                </Card.Title>{' '}
                <Card.Title className="d-flex justify-content-between bg-secondary bg-gradient text-white p-2 h6 shadow">
                  {pdpMember.program_name_local_lang_label}
                  <span>{pdpMember.program_name__local_language}</span>
                </Card.Title>
                <hr />
                <div className="flex-column card-text">
                  <h5 className="bg-secondary bg-gradient text-white p-2 h6 shadow">
                    {pdpMember.program_information[0].program_information_label}
                  </h5>
                  <br />
                  <ul className="card-list_wrapper">
                    <li className="card-list-item fw-bolder">
                      {pdpMember.program_information[0].area_label}:
                      <span> {pdpMember.program_information[0].area}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.program_information[0].certificae_label}:
                      <span>
                        {' '}
                        {pdpMember.program_information[0].certificate}
                      </span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.program_information[0].ects_label}:
                      <span> {pdpMember.program_information[0].ects}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.program_information[0].area_label}:
                      <span> {pdpMember.program_information[0].area}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.program_information[0].website_label}:
                      <a
                        className="text-primary"
                        href={pdpMember.program_information[0].website.url}
                        target={pdpMember.program_information[0].website.target}
                      >
                        {' '}
                        {pdpMember.program_information[0].website.url}
                      </a>
                    </li>
                    <hr />
                  </ul>
                </div>{' '}
                <div className="flex-column card-text">
                  <h5 className="bg-secondary bg-gradient text-white p-2 h6 shadow">
                    {pdpMember.hei_information[0].hei_information_label}
                  </h5>
                  <br />
                  <ul className="card-list_wrapper">
                    <li className="card-list-item fw-bolder">
                      {pdpMember.hei_information[0].hei_name_label}:
                      <span> {pdpMember.hei_information[0].hei_name}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.hei_information[0].hei_name_local_lang_label}:
                      <span>
                        {' '}
                        {pdpMember.hei_information[0].hei_name__local_language}
                      </span>
                    </li>
                    <br />
                    <li className="card-list-item fw-bolder">
                      {pdpMember.hei_information[0].street_label}:
                      <span> {pdpMember.hei_information[0].street}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.hei_information[0].city_label}:
                      <span> {pdpMember.hei_information[0].city}</span>
                    </li>{' '}
                    <li className="card-list-item fw-bolder">
                      {pdpMember.hei_information[0].country_label}:
                      <span> {pdpMember.hei_information[0].country}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.hei_information[0].website_label}:
                      <a
                        className="text-primary"
                        href={pdpMember.hei_information[0].website.url}
                        target={pdpMember.hei_information[0].website.target}
                      >
                        {' '}
                        {pdpMember.hei_information[0].website.url}
                      </a>
                    </li>
                    <hr />
                  </ul>
                </div>
                <div className="flex-column card-text">
                  <h5 className="bg-secondary bg-gradient text-white p-2 h6 shadow">
                    {
                      pdpMember.accreditation_details[0]
                        .accreditation_details_label
                    }
                  </h5>
                  <br />
                  <ul className="card-list_wrapper">
                    <li className="card-list-item fw-bolder">
                      {pdpMember.accreditation_details[0].period_label}:
                      <span>
                        {' '}
                        {
                          pdpMember.accreditation_details[0].accreditation_from
                        }{' '}
                        {pdpMember.accreditation_details[0].until_label}{' '}
                        {pdpMember.accreditation_details[0].accreditation_until}
                      </span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {pdpMember.enter_acreditation_level_label}:
                      <span> {pdpMember.enter_accreditation_level}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {
                        pdpMember.accreditation_details[0]
                          .accreditation_agency_label
                      }
                      :
                      <span>
                        {' '}
                        {
                          pdpMember.accreditation_details[0]
                            .accreditation_agency
                        }
                      </span>
                    </li>
                    <hr />
                  </ul>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query pdpMemberQuery($id: String, $lang: String) {
    prismicPdpMember(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        program_name
        program_name__local_language
        program_name_label
        program_name_local_lang_label
        accreditation_details {
          accreditation_details_label
          accreditation_from(formatString: "DD/MM/YYYY")
          accreditation_until(formatString: "DD/MM/YYYY")
          period_label
          until_label
          accreditation_agency_label
          accreditation_agency
        }
        enter_accreditation_level
        hei_information {
          city
          city_label
          country
          country_label
          hei_information_label
          hei_name
          hei_name__local_language
          hei_name_label
          hei_name_local_lang_label
          street
          street_label
          website {
            url
            target
          }
          website_label
        }
        program_information {
          area
          area_label
          certificae_label
          certificate
          ects
          ects_label
          program_information_label
          website {
            target
            url
          }
          website_label
        }
        enter_acreditation_level_label
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(PdpMemberTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
